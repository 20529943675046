import '@/styles/index.scss' // global css
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MetaInfo from 'vue-meta-info'
// import VueLazyload from 'vue-lazyload'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MetaInfo)
// Vue.use(VueLazyload, {
//   error: require("https://static.dasdt.cn/images/empty.png"), // 图片加载失败显示
//   loading: require("https://static.dasdt.cn/images/empty.png") // 图片加载中显示
// });

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')

