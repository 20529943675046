import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/recommendedPositions',
        name: 'recommendedPositions',
        component: () => import('../views/recommendedPositions/index.vue')
    },
    {
        path: '/position',
        name: 'recommendedPositions',
        component: () => import('../views/position/index.vue')
    },
    {
        path: '/positionDetails/:id',
        name: 'positionDetails',
        component: () => import('../views/positionDetails/index.vue')
    },
    {
        path: '/enterpriseDetails/:id',
        name: 'enterpriseDetails',
        component: () => import('../views/enterpriseDetails/index.vue')
    },
    {
        path: '/course',
        name: 'course',
        component: () => import('../views/course/index.vue')
    },
    {
        path: '/courseDetals/:id',
        name: 'courseDetals',
        component: () => import('../views/courseDetails/index.vue')
    },
    {
        path: '/PersonalCenter',
        name: 'PersonalCenter',
        component: () => import('../views/PersonalCenter/index.vue')
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy/index.vue')
    },
    {
        path: '/Feedback',
        name: 'Feedback',
        component: () => import('../views/Feedback/index.vue')
    },
    {
        path: '/MessageCenter',
        name: 'MessageCenter',
        component: () => import('../views/MessageCenter/index.vue')
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('../views/agreement/index.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.afterEach((to) => {
    if (to.path == "/") {
        document.querySelector("body").setAttribute("style", "overflow: auto !important;")
    }
});
export default router
