<template>
  <div class="home_banner">
    <div class="wrap">
      <div class="navs" @mouseover="handleOver"
           @mouseleave="handleOut">
        <div class="item" v-for="(item, index) in navList" :key="index">
          <div class="itemWrap" :data-index="index">
            <div class="title" style="width: 56px">{{ item.name }}</div>
            <div class="its" v-for="(its, index) in item.nodes.slice(0,2)" :key="index">{{ its.name }}<span>/</span></div>
          </div>
          <img src="https://static.dasdt.cn/images/right.png" alt="">
        </div>
        <div class="menus" v-show="activeIndex">
          <div class="menusWrap">
            <div class="title" style="cursor: pointer;" @click="goPostion(seMenuInfo.id, 1)">{{ seMenuInfo.name }}</div>
            <div class="lists">
              <div class="items" v-for="(item, index) in seMenuInfo.nodes" :key="index">
                <div class="name" style="cursor: pointer" @click="goPostion(item.id, 2)">{{ item.name }}</div>
                <div class="tagLists">
                  <div class="tags" v-for="(its, indexs) in item.nodes" :key="indexs" @click="goPostion(its.id,3)">{{ its.name }}</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner">
        <el-carousel height="350px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.bgImage.url" alt="" @click="goto(item.targetProps.pcMemberUrl)">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div style="z-index: 99">
      <div class="interviewInfo" style="height: 180px;overflow: hidden">
        <div class="info">
          <img :src="getFace($store.getters.userInfo)" alt="">
          <div class="right">
            <div class="name" v-if="$store.getters?.userInfo?.name">{{ $store.getters?.userInfo?.name }}</div>
            <div class="name" v-else @click="login">请登录/注册</div>
          </div>
        </div>
        <div class="tabsWrap">
          <div class="tabs">
            <div class="item">
              <div class="num">{{ person?.deliver?person?.deliver:0 }}</div>
              <div class="name">已投递</div>
            </div>
            <div class="item">
              <div class="num">{{ person?.interview?person?.interview:0 }}</div>
              <div class="name">待面试</div>
            </div>
          </div>
          <div class="bunUserCenter" @click="goCenter">个人中心</div>
        </div>
      </div>
      <div class="interviewInfo" style="height:76px;overflow: hidden;background: #FFFFFF;margin-top: 14px;padding-top: 8px;padding-bottom: 12px">
        <div style="display: flex;align-items: center;justify-content: space-around">
          <div class="its" @click="goCenter">
            <img src="https://static.dasdt.cn/images/userCenter/Toppings.png">
            <span>在线简历</span>
          </div>
          <div class="its" @click="goCenter('Upload')">
            <img src="https://static.dasdt.cn/images/userCenter/Toppings.png">
            <span>上传附件</span>
          </div>
          <div class="its" @click="goCenter2">
            <img src="https://static.dasdt.cn/images/userCenter/mainshi.png">
            <span>面试记录</span>
          </div>
        </div>
      </div>
      <img class="wanshanjianli" src="https://static.dasdt.cn/images/wanshanjianli.png">
    </div>

    <logins v-model="isShow2"/>
    <el-dialog
        :visible.sync="dialogVisible2"
        width="485px"
        title="面试详情"
        :before-close="handleClose2">
      <div class="InterviewDetalis">
        <div class="table">
          <div class="item">
            <div class="title">面试轮次</div>
            <div class="value">第{{ jobDetails?.round }}轮</div>
          </div>
          <div class="item">
            <div class="title">面试时间</div>
            <div class="value">{{ jobDetails?.interviewTimeFormat }}</div>
          </div>
          <div class="item">
            <div class="title">面试官</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].contact }}</div>
          </div>
          <div class="item" v-if="jobDetails?.interviewData?.[0].url">
            <div class="title">面试链接</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].url }}</div>
          </div>
          <div class="item" v-else>
            <div class="title">面试地点</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].interviewAddress }}</div>
          </div>
          <div class="item">
            <div class="title">联系电话</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].contactPhone }}</div>
          </div>

        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import categoryJob from '@/assets/categoryJob.json'
import {pageMine} from "@/api/position";
import {pagePCMember, personal} from "@/api/currency";
// import {getToken} from "@/utils/auth";
import logins from "@/components/login/index.vue";
import store from "@/store";

export default {
  components: {logins},
  data() {
    return {
      jobDetails: {},
      isShow2: false,
      activeName: '1',
      navList: categoryJob.data,
      activeIndex: null,
      pagination: {
        number: 1,
        limit: 3
      },
      value: 'INTERVIEW',
      flag: null,
      list: [],
      bannerList: [],
      dialogVisible2: false,
      person: {}
    }
  },
  created() {

    if(this.isLogin) {
      if(Object.keys(this.$store.getters.userInfo).length!==0) {
        this.pageMine()
        this.getPersonal()
      }
    }
    this.pagePCMember()


  },
  computed: {
    seMenuInfo() {
      if (this.activeIndex === null) return [];
      return this.navList[this.activeIndex];
    },
    isLogin() {
      return this.$store.getters.token !== '0';
    }
  },
  methods: {
    getFace(member) {
      if (member && member.gender === '男') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nan.png'
      } else if (member && member.gender === '女') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nv.png'
      } else {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/deful.png'
      }
    },
    async getPersonal() {
      const {data} = await personal()
      this.person = data[0]
    },
    login() {
      store.commit('showLogin', true)
    },
    goCenter2() {
      if(this.isLogin){
        this.$router.push('/PersonalCenter?id=2')
      }else{
        store.commit('showLogin', true)

      }

    },
    goCenter(e) {
      if(this.isLogin){
        if(e === 'Upload') {
          this.$router.push('/personalCenter?Upload=1')
          return
        }
        this.$router.push('/personalCenter')
      }else{
        store.commit('showLogin', true)

      }
    },

    goto(url) {
      window.open(url)
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    gopersonCenter(item) {
      if(this.activeName === '1') {
        this.jobDetails = item
        this.dialogVisible2 = true
      }

    },
    async pagePCMember() {
      const {data} = await pagePCMember('first_page')
      this.bannerList = data
    },
    goPostion(id, level) {
      const info = JSON.stringify({id, level})
      this.$router.push(`/position?info=${info}`)
    },
    async pageMine() {
      const res = await pageMine({status: this.value, flag: this.flag}, this.pagination)
      this.list = res.data
    },
    handleClick() {
      if(this.isLogin) {
        if (this.activeName === '1') {
          this.value = 'INTERVIEW'
          this.flag = 1
          this.pageMine()
        } else if (this.activeName === '2') {
          this.value = ''
          this.flag = null
          this.pageMine()
        }
      }else{
        this.isShow2 = true
      }

    },
    handleOver({target}) {
      let index = target.dataset
          ? target.dataset.index
          : target.getAttribute("data-index");
      if (
          target.nodeName === "DIV" &&
          index !== undefined &&
          this.activeIndex !== index
      ) {
        this.activeIndex = index;
      }
    },
    handleOut() {
      this.activeIndex = null;
    },
    goDetails(id) {
      // this.$router.push(`/positionDetails?id=${id}`)
      // this.$router.push({name: 'positionDetails', params: {id: id}})
      this.$router.push('/positionDetails/' + id)
    }
  }
}
</script>

<style lang="scss">
.home_banner {
z-index: 999;
  width: 1200px;
  margin: 24px auto 48px auto;
  height: 350px;
  display: flex;
  .wanshanjianli{
    width: 288px;
    height: 66px;
    margin-left: 16px;
    margin-top: 14px;
  }
  //align-items: center;

  justify-content: space-between;
  .InterviewDetalis {
    padding-bottom: 20px;

    .btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      .box1 {
        border: 1px solid #ADADAD;
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .box2 {
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .table {
      display: flex;
      flex-direction: column;

      .item {
        border-bottom: 1px solid #E3E4E7;
        height: 64px;
        display: flex;
        align-items: center;

        .title {
          width: 178px;
          color: #808080;
          font-size: 16px;
          font-weight: 400;
        }

        .value {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
        }

        .btn {
          padding: 6px 8px;
          background: rgba($color: #000000, $alpha: 0.04);
          border-radius: 2px;
          color: rgba($color: #000000, $alpha: 0.45);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;

    .navs {
      background: #fff;
      height: 100%;
      width: 228px;
      border-radius: 12px 0px 0px 12px;
      display: flex;
      flex-direction: column;
      position: relative;

      .menus {
        border: 1px solid #EDEDED;
        position: absolute;
        top: 0;
        left: 220px;
        z-index: 999;
        color: #fff;
        width: 980px;
        border-radius: 0 8px 8px 0;
        height: 350px;
        background: #fff;
        overflow-y: auto;

        .menusWrap {
          padding: 20px;

          .lists {
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            gap: 18px;

            .items {
              display: flex;

              .name {
                color: #555555;
                font-size: 14px;
                font-weight: 400;
                width: 164px;
              }

              .tagLists {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                flex: 1;

                .tags {
                  cursor: pointer;
                  padding-right: 8px;
                  color: rgba($color: #000000, $alpha: 0.65);
                  font-size: 14px;
                  font-weight: 400;

                  &:hover {
                    color: #185BE0;
                  }
                }
              }

            }
          }

          .title {
            color: #1F1F1F;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      .item {
        //padding: 9.5px 16px;
        width: 228px;
        height: 38px;
        justify-content: center;
        display: flex;
        align-items: center;

        img {
          width: 6px;
          height: 6px;
          margin-left: 8px;
        }

        .itemWrap {
          width: 188px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;

          .title {
            font-size: 14px;
            font-weight: 500;
            color: #1F1F1F;
            margin-right: 12px;
            white-space: nowrap;
          }

          .its {
            color: rgba($color: #1F1F1F, $alpha: 0.6);
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            display: flex;

            span {
              display: none;
            }
          }

          .its:not(:last-child) {
            span {
              display: block !important;
            }
          }
        }

      }
    }

    .banner {
      width: 668px;
      height: 350px;
      border-radius: 0 12px 12px 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0 12px 12px 0;

      }
    }
  }

  .interviewInfo {
    margin-left: 16px;
    width: 288px;
    background: #F7FBFF;
    border-radius: 12px;
    .its{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      img{
        width: 32px;
        height: 32px;
      }
      span{
        color: #1C1D24;
        font-size: 14px;
      }
    }

    .tabsWrap {
      height: 116px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bunUserCenter{
        border: 1px solid #185BE0;
        width: 240px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        color: #185BE0;
        font-size: 14px;
      }
      .tabs{
        margin-top: 8px;
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        .item{
          display: flex;
          flex-direction: column;
          align-items: center;
          .num{
            color: #33343C;
            font-weight: 700;
            font-size: 28px;
          }
          .name{
            color: #808080;
            font-size: 14px;
          }
        }
      }
    }

    .info {
      margin: 14px 16px 14px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .right {
        display: flex;
        flex-direction: column;
        // gap: 8px;
        .name {
          font-size: 14px;
          color: #1F1F1F;
          font-weight: 500;
        }

        .desc {
          color: rgba($color: #1F1F1F, $alpha: 0.6);
          font-size: 12px;
          font-weight: 400;
        }
      }

      img {
        border-radius: 50%;
        width: 36px;
        height: 36px;
      }

    }
  }
}
</style>
